<template>
  <!--begin::Content-->
  <div
    class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
  >
    <div class="d-flex flex-column-fluid flex-center">
      <!--begin::ResetPassword-->
      <div class="login-form">
        <!--begin::Form-->
        <div class="pb-13 pt-lg-0 pt-5">
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
            Registrierung abgeschlossen.
          </h3>
          <p class="text-muted font-weight-bold font-size-h4">
            Sie können sich jetzt mit ihrem Benutzername und Passwort anmelden.
          </p>
        </div>
        <div class="form-group d-flex flex-wrap pb-lg-0">
          <button
            type="button"
            class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3"
            @click="$router.push({ name: 'login' })"
          >
            Login
          </button>
        </div>
      </div>
      <!--end::ResetPassword-->
    </div>
  </div>
  <!--end::Content-->
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState } from "vuex";
import { VERIFY_REGISTRATION } from "@/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "VerifyEmail",
  data() {
    return {
      key: this.$route.params.key
    };
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    this.submitVerifyEmail();
  },
  methods: {
    submitVerifyEmail() {
      // send reset password request
      this.$store
        .dispatch(VERIFY_REGISTRATION, { key: this.key })
        // go to which page after successfully login
        .then(() => {
          Swal.fire({
            title: "Registrierung abgeschlossen",
            text:
              "Sie können sich jetzt mit ihrem Benutzername und Passwort anmelden.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-secondary"
            },
            willClose: () => {
              this.$router.push({ name: "login" });
            }
          });
        })
        .catch(() => {
          Swal.fire({
            title: "OOPS!",
            text:
              "Ihr Aktivierungs-Link ist ungültig oder wurde bereits verwendet.",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-secondary"
            }
            // willClose: () => {
            //   this.$router.push({ name: "login" });
            // }
          });
        });
    }
  }
};
</script>
